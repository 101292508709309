import every from 'lodash/every';

import { ItemMessagesFilters } from '../../components/list/ItemMessagesList';
import { MessageMessageTypes } from '../../messagesTypes';

interface IsChatGptMessagesModeOptions {
  messagesFilters: ItemMessagesFilters;
}

function isChatGptMessagesMode({
  messagesFilters
}: IsChatGptMessagesModeOptions): boolean {
  return (
    messagesFilters?.messageTypeTextFilter?.in?.length > 0 &&
    every(
      messagesFilters?.messageTypeTextFilter?.in,
      (messageType: MessageMessageTypes) =>
        [
          MessageMessageTypes.ANSWER_CHATGPT_DISCUSSION_MESSAGE,
          MessageMessageTypes.REQUEST_CHATGPT_DISCUSSION_MESSAGE
        ].includes(messageType)
    )
  );
}

export default isChatGptMessagesMode;
